@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
body {
    margin: 0;
    overflow-x: hidden;
    font-family: 'DM Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0E1D39;
}

h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
}

h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
}

h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
}

h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 31.25px;
}

h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 23.44px;
}

a {
    text-decoration: none;
}

.show-desktop {
    display: none;
}

.show-mobile {
    display: block;
}

@media (min-width: 768px) {
    h1 {
        font-weight: 500;
        font-size: 72px;
        line-height: 80px;
    }
    h2 {
        font-weight: 500;
        font-size: 64px;
        line-height: 72px;
    }
    h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
    }
    h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 31.25px;
    }
    h5 {
        font-weight: 700;
        font-size: 20px;
        line-height: 41.38px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 23.44px;
    }
    .show-desktop {
        display: block;
    }
    .show-mobile {
        display: none;
    }
}