.execute-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  width: 100%;
  overflow: hidden;
}

.execute-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  text-align: center;
}

.execute-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0e1d39;
  margin: 0;
}

.execute-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
}

.execute-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.selectors-row {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  width: 100%;
}

.selector-container-in-row {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.selector-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.selector-container-fit-width {
  width: fit-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.selector-container label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
}

.version-select {
  width: 100%;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #d0d5dd;
  background-color: transparent;
  color: #0e1d39;
  font-size: 14px;
  line-height: 1.5;
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding-right: 2.5rem;
}

.version-select:focus {
  border-color: #007bff;
}

.version-select option {
  width: 100%;
  padding: 0.625rem 1rem;
  background-color: #fff;
  color: #0e1d39;
}

.input-row {
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
  width: 100%;
  position: relative;
}

.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background-color: #007bff;
  color: white;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 100px;
  height: 44px;
}

.send-button:hover {
  background-color: #0056b3;
}

.send-button:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.result-container {
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  overflow: hidden;
}

.result-content {
  padding: 1rem;
  font-size: 14px;
  line-height: 1.6;
  color: #374151;
  max-height: 400px;
  overflow-y: auto;
}

.result-content pre {
  white-space: pre-wrap;
  word-break: break-word;
}

.error-message {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fee2e2;
  color: #b91c1c;
}